<h2 mat-dialog-title class="modal-title"
    [style.background-color]="backgroundColor">{{dialogHeader}}</h2>
<mat-dialog-content>
  <p>{{dialogText}}</p>
</mat-dialog-content>
<mat-dialog-actions class="mt-4 d-flex justify-content-between">
  <button mat-button [mat-dialog-close]="false" class="btn btn-primary">{{cancelBtnText}}</button>
  <button mat-button [mat-dialog-close]="true" class="btn btn-danger"
          [style.background-color]="backgroundColor"
          cdkFocusInitial>{{confirmBtnText}}</button>
</mat-dialog-actions>
