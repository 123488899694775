<div class="login-wrapper">
  <div class="purple-rectangle">
    <img src="assets/images/logo.png" class="logo" alt="Logo">
    <div class="centered-text">
      <p>Welcome to Dionysus Libation Lab, where the art of mixology meets community spirit.
        Unleash your inner bartender and discover a world of cocktails with ingredients you have at home.
        Join the celebration – our group system lets you host cocktail parties. Cheers to crafting unforgettable moments,
        one cocktail at a time! </p>
    </div>
  </div>

  <div class="pt-0 content-wrapper">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <span class="anchor"></span>

        <div class="card rounded-0">
          <div class="card-header header-resetPassword">
            <p class="mb-0">Reset Password</p>
          </div>

          <div class="card-body">
            <form class="form" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">

              <div class="form-group newPassword-field">

                <input type="password" name="newPassword" formControlName="newPassword" class="form-control custom-input-field" id="inputNewPassword"
                       aria-describedby="newPasswordHelp" placeholder="New Password">
                <div *ngIf="submitted && resetPasswordForm.controls.newPassword.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="resetPasswordForm.controls.newPassword.errors.required">Password required!
                  </small>
                  <small class="form-text custom-text-warning" *ngIf="resetPasswordForm.controls.newPassword.errors.minlength">Your password
                    must be at least 8 characters long!
                  </small>
                </div>
              </div>

              <div class="form-group repeat-newPassword-field">

                <input type="password" name="repeatNewPassword" formControlName="repeatNewPassword" class="form-control custom-input-field" id="inputRepeatNewPassword"
                       aria-describedby="repeatNewPasswordHelp" placeholder="Repeat New Password">
                <div *ngIf="submitted && resetPasswordForm.controls.repeatNewPassword.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="resetPasswordForm.controls.repeatNewPassword.errors.required">Password required!
                  </small>
                  <small class="form-text custom-text-warning" *ngIf="resetPasswordForm.controls.repeatNewPassword.errors.minlength">Your password
                    must be at least 8 characters long!
                  </small>
                </div>
              </div>

              <button type="button" (click)="onSubmit()" class="btn btn-success btn-purple-width">Reset Password</button>

              <div>
                <a routerLink="/login" class="btn btn-link signIn-text">You dont want to reset your Password? Sign in</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
