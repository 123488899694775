<div class="login-wrapper">
  <div class="purple-rectangle">
    <img src="assets/images/logo.png" class="logo" alt="Logo">
    <div class="centered-text">
      <p>Welcome to Dionysus Libation Lab, where the art of mixology meets community spirit.
        Unleash your inner bartender and discover a world of cocktails with ingredients you have at home.
        Join the celebration – our group system lets you host cocktail parties. Cheers to crafting unforgettable moments,
        one cocktail at a time! </p>
    </div>
  </div>

  <div class="pt-0 content-wrapper">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <span class="anchor"></span>
        <div class="card rounded-0">
          <div class="card-header header-forgotPassword">
            <p class="mb-0">Forgot Password</p>
          </div>

          <div class="card-body">
            <form class="form">
              <div class="form-group email-field">
                <input type="email" name="email" [(ngModel)]="email" class="form-control custom-input-field" id="inputEmail"
                       aria-describedby="emailHelp" placeholder="Your Email">
              </div>

              <button type="button" (click)="onSubmit()" class="btn btn-success btn-purple-width">Forgot Password</button>

              <div>
                <a routerLink="/login" class="btn btn-link signIn-text">You know your Password? Sign in</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
