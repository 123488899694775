<div class="login-wrapper">
  <div class="purple-rectangle">
    <img src="assets/images/logo.png" class="logo" alt="Logo">
    <div class="centered-text">
      <!-- todo: change the placeholder text -->
      <p>Welcome to Dionysus Libation Lab, where the art of mixology meets community spirit.
        Unleash your inner bartender and discover a world of cocktails with ingredients you have at home.
        Join the celebration – our group system lets you host cocktail parties. Cheers to crafting unforgettable moments,
        one cocktail at a time! </p>
    </div>
  </div>

  <div class="pt-0 content-wrapper">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <span class="anchor" id="formLogin"></span>

        <div class="card rounded-0">
          <div class="card-header header-login">
            <p class="mb-0">Login</p>
          </div>

          <div class="card-body">
            <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser()">

              <div class="form-group username-field">

                <input type="text" name="username" formControlName="username" class="form-control custom-input-field" id="inputUsername"
                       aria-describedby="usernameHelp" placeholder="Email">
                <div *ngIf="submitted && loginForm.controls.username.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="loginForm.controls.username.errors.required">Your username is
                    required!
                  </small>
                </div>
              </div>

              <div class="form-group password-field">

                <input type="password" name="password" formControlName="password" class="form-control custom-input-field" id="password"
                       placeholder="Password">
                <div *ngIf="submitted && loginForm.controls.password.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="loginForm.controls.password.errors.required">Your password
                    is
                    required!
                  </small>
                  <small class="form-text custom-text-warning" *ngIf="loginForm.controls.password.errors.minlength">Your password
                    must be at least 8 characters long!
                  </small>
                </div>
              </div>

              <div>
                <a routerLink="/forgot-password" class="btn btn-link register-text">Forgot Password?</a>
              </div>

              <button type="button" (click)="loginUser()" class="btn btn-success btn-purple-width">Login</button>

              <div>
                <a routerLink="/register" class="btn btn-link register-text">or sign up!</a>
              </div>
            </form>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
