<app-header></app-header>

<div class="grid-container">
  <div class="grid-item">
    <div>
      <h3>Notifications</h3>
    </div>
    <div *ngIf="getMessage().length === 0">
      <div class="card-body">
        <h5 class="col-xl-12 col-lg-12 card-title">No Messages</h5>
      </div>
    </div>
    <div class="col-xl-12 col-lg-12 message-list" *ngIf="getMessage().length > 0">
      <div class="message-header">
        <span class="col-xl-2 col-lg-2 p-1 hidden-span"></span>
        <p class="col-xl-8 col-lg-8 p-1 unread-message-count">{{getUnreadMessageCount()}} unread Message</p>
        <div class="col-xl-2 col-lg-2 p-1 card-buttons">
          <button class="btn btn-envelope-open btn-m responsive-info-button"
                  aria-label="Set all Messages to read"
                  *ngIf="getUnreadMessageCount() > 0"
                  (click)="setAllMessagesRead()">
            <i class="bi bi-envelope-open-fill"></i>
          </button>
          <button class="btn btn-envelope-open btn-m responsive-info-button"
                  aria-label="Set all Messages to unread"
                  *ngIf="getUnreadMessageCount() === 0"
                  (click)="setAllMessagesUnread()">
            <i class="bi bi-envelope-fill"></i>
          </button>
        </div>
      </div>
      <p class="unread-message-count-line"></p>
      <div class="col-xl-12 col-lg-12 border-bottom" *ngFor="let message of getMessage()">
        <div class="card-body"
             [ngStyle]="{'background-color':message.isRead === false ? 'rgba(217, 217, 217, 1)' : 'rgba(190, 190, 190, 1)'}">
          <h5 class="card-title col-xl-2 col-lg-2 p-1">{{message.group.name}}</h5>
          <h6 class="card-subtitle mb-2 text-muted col-xl-2 col-lg-2 p-1">{{message.sentAt | date}}</h6>
          <div class="card-text col-xl-6 col-lg-6 p-1">{{message.text}}</div>
          <div class="card-buttons col-xl-2 col-lg-2 p-1">
            <div class="btn-group">
              <button class="btn btn-info btn-sm responsive-info-button"
                      aria-label="Accept GroupOverview Invitation"
                      (click)="acceptInvitation(message)">
                <i class="bi bi-check-lg"></i>
              </button>
              <button class="btn btn-danger btn-sm responsive-warning-button"
                      aria-label="Decline GroupOverview Invitation"
                      *ngIf="message.isRead === false"
                      (click)="declineInvitation(message)">
                <i class="bi bi-x-lg"></i>
              </button>
              <button class="btn btn-trash btn-sm responsive-danger-button"
                      aria-label="Trash GroupOverview Invitation"
                      *ngIf="message.isRead === true"
                      (click)="deleteMessage(message)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


