<app-header></app-header>


<div class="grid-container">
  <div class="grid-item">
    <div>
      <h3>Edit {{group.name}}</h3>
    </div>

    <form #form="ngForm" [class.was-validated]="form.submitted" (ngSubmit)="onSubmit(form)">

      <div class="form-grid">
        <div class="form-column">
          <!-- This is the form for the group name -->
          <div class="input-row p-2">
            <label for="groupName" class="p-2">
              Name
            </label>
            <div class="input-field w-50">
              <input type="text"
                     id="groupName"
                     name="groupName"
                     placeholder="Group name"
                     class="form-control"
                     [class]="dynamicCssClassesForInput(groupNameModel)"
                     [(ngModel)]="group.name"
                     #groupNameModel="ngModel"
                     required
                     autofocus
              >
              <div class="invalid-feedback">
                <!-- This is shown, when the form validation deems the name input to be invalid -->
                Name is required!
              </div>
            </div>
          </div>

          <!-- Add group members -->
          <div class="wrapper input-row p-2">
            <label for="memberSelectionInput" class="p-2">
              Add Member
            </label>
            <div class="wrapper w-50">
              <app-autocomplete
                id="memberSelectionInput"
                name="memberSelection"
                textInputClass="form-control"
                [placeholderText]="'Search for users'"
                [suggestions]="memberSuggestions"
                [formatModel]="formatMember"
                valueNeedsToMatchSuggestion="true"
                [(ngModel)]="user"
              >
              </app-autocomplete>

              <button type="button" class="btn rounded-button" (click)="addMember(user)">
                <i class="bi bi-person-fill-add"></i>
              </button>
            </div>
          </div>
          <div id="del-btn">
            <button type="button"
                    class="btn btn-danger custom-button-del" (click)="onDelete()">
              Delete group
            </button>
          </div>
        </div>

        <!-- List of group members -->
        <div class="table-column">
          <div class="table-container">
            <p class="entry-count">{{ group.members.length }} User in Group</p>

            <table class="table align-baseline equal-columns">
              <tbody>
              <tr class="list-row" *ngFor="let member of group.members; index as i">


                <td class="member-column">{{formatMember(member)}}</td>
                <td class="table-element table-button">
                  <!-- Make button just hidden, when no participant is present, to keep layout correct -->
                  <mat-checkbox *ngIf="group.host.name !== member.name"
                                (change)="membersSelectedForDeletion[i] = !membersSelectedForDeletion[i]"
                                name="memberCheckbox{{i}}" style="padding: 0 0.75rem 0 0.75rem" [color]="'warn'"
                                [style.visibility]="member ? 'visible' : 'hidden'"></mat-checkbox>
                  <div *ngIf="group.host.name === member.name" class="crown">👑</div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="table-footer">
              <button type="button"
                      class="btn btn-danger custom-button-del remove-members-btn" [disabled]="!isAnyMemberSelected()"
                      (click)="removeSelectedMembers()">
                Remove selected
              </button>
            </div>
          </div>
          <button type="submit"
                  class="btn btn-primary custom-button"
                  [disabled]="!form.valid">
            Save
          </button>

        </div>
      </div>
    </form>
  </div>
</div>
