import { Component } from '@angular/core';

@Component({
  selector: 'app-cocktail-detail-page',
  templateUrl: './cocktail-detail-page.component.html',
  styleUrls: ['./cocktail-detail-page.component.scss']
})
export class CocktailDetailPageComponent {

}
