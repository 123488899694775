<mat-dialog-content class="grid-container">
  <div>
    <h3 class="modal-title">Cocktail Card Suggestions</h3>
  </div>


  <div *ngIf="menus !== null" class="content-grid">

    <div class="table-column">
      <div class="table-container">
        <p class="entry-count">our suggestions
          <span class="bi bi-info-circle"
                ngbPopover= "Libation Value (LV) represents how many preferences are fulfilled a value greater than 1.0 indicates that every preference is fulfilled at least once.
                             The higher the Libation Value the better the suggestion."
                triggers="mouseenter:mouseleave">
          </span>
        </p>

        <div class="container-scrollable">
          <table class="table align-baseline equal-columns">
            <tbody>
            <tr class="list-row" *ngFor="let menu of menus.menuList; index as i">
              <td *ngIf="i===0" [ngClass]="{'menu-row-selected': i === currentMenu, 'menu-row': true}" (click)="currentMenu = i">
                <span>Optimized Suggestion</span> <span style="margin-left: 1.5em">LV: {{ menu.lv | number: '1.2-2'}}</span>
              </td>
              <td *ngIf="i!==0" [ngClass]="{'menu-row-selected': i === currentMenu, 'menu-row': true}" (click)="currentMenu = i">
                Versatile Suggestion {{i}} <span style="margin-left: 1em">LV: {{ menu.lv | number: '1.2-2'}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <div class="cocktail-column">
      <div class="cocktail-container">
        <div class="cocktail-container-grid">
          <div *ngFor="let cocktail of currentMenuCocktails.cocktailMenu; index as i" class="mb-3 cocktail-item">
            <div class="card h-100">
              <div class="container-fluid card-header">{{ cocktail.name }}</div>
              <div class="card-body">
                <img src="{{ cocktail.imagePath }}" class="img-fluid" alt="Responsive image">
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex add-action">
          Use this Suggestion!
          <button (click)="addMenu()" class="btn rounded-button">
            Add
            <i class="bi bi-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
  <h4 *ngIf="menus === null" class="text-center">Loading...</h4>

</mat-dialog-content>
