<div class="register-wrapper">
  <div class="purple-rectangle">
    <img src="assets/images/logo.png" class="logo" alt="Logo">
    <div class="centered-text">
      <p>Welcome to Dionysus Libation Lab, where the art of mixology meets community spirit.
        Unleash your inner bartender and discover a world of cocktails with ingredients you have at home.
        Join the celebration – our group system lets you host cocktail parties. Cheers to crafting unforgettable moments,
        one cocktail at a time! </p>
    </div>
  </div>

  <div class="pt-0 content-wrapper">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <span class="anchor" id="formLogin"></span>

        <div class="card rounded-0">
          <div class="card-header header-login">
            <p class="mb-0">Create New Account</p>
          </div>

          <div class="card-body">
            <form class="form" [formGroup]="createAccountForm" (ngSubmit)="createUser()">

              <div class="form-group username-field">
                <input type="text" name="username" formControlName="username" class="form-control custom-input-field" id="inputUsername"
                       aria-describedby="usernameHelp" placeholder="Username">
                <div *ngIf="submitted && createAccountForm.controls.username.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="createAccountForm.controls.username.errors.required">Your username is
                    required!
                  </small>
                </div>
              </div>

              <div class="form-group email-field">
                <input type="email" name="email" formControlName="email" class="form-control custom-input-field" id="email"
                       placeholder="Your Email">
                <div *ngIf="submitted && createAccountForm.controls.email.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="createAccountForm.controls.email.errors.required">Your Email
                    is
                    required!
                  </small>
                </div>
              </div>

              <div class="form-group password-field">
                <input type="password" name="password" formControlName="password" class="form-control custom-input-field" id="password"
                       placeholder="Password">
                <div *ngIf="submitted && createAccountForm.controls.password.errors" class="error">
                  <small class="form-text custom-text-warning" *ngIf="createAccountForm.controls.password.errors.required">Your password
                    is
                    required!
                  </small>
                  <small class="form-text custom-text-warning" *ngIf="createAccountForm.controls.password.errors.minlength">Your password
                    must be at least 8 characters long!
                  </small>
                </div>
              </div>

              <button type="button" (click)="createUser()" class="btn btn-success btn-purple-width">Create Account</button>
              <div>
                <a routerLink="/login" class="btn btn-link signIn-text">Already have an account? Sign in</a>
              </div>

            </form>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
```
