<div *ngIf="isModal" class="modal-header">
  <h3>{{cocktail.name}}</h3>
</div>

<div class="grid-container" [ngClass]="isModal ? 'modal-height' : 'page-height'">

  <div class="left-container col-lg-9 col-md-9 col-sm-9 col-9">

    <div *ngIf="!isModal" class="page-header">
      <h2> {{cocktail.name}} </h2>
    </div>

    <div class="prefs">
      <span class="preference" *ngFor="let preference of cocktail.preferenceName">{{preference}}</span>
    </div>


    <div class="inner-grid-container">

      <div class="grid-item">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="m-0">Ingredients</h3>
        </div>
        <div class="grid-item-content">
          <ul>
            <li *ngFor="let ingredient of cocktail.ingredients|keyvalue">
              {{ingredient.value}} {{ingredient.key}}
            </li>
          </ul>
        </div>
        <div>

        </div>
      </div>

      <div class="grid-item">
        <div class="d-flex flex-column justify-content-center">
          <h3 class="m-0">Instructions</h3>
        </div>
        <div class="grid-item-content">
          <p>{{cocktail.instructions}}</p>
        </div>
      </div>

    </div>

    <div class="d-flex justify-content-end share-btn m-3 mt-4">
      <button class="btn rounded-button" (click)="shareCocktail()">
        <i class="bi" [ngClass]="shared ? 'bi-check2' : 'bi-share'"></i>
        Share Cocktail
      </button>
    </div>


  </div>

  <div class="right-container col-lg-3 col-md-3 col-sm-3 col-3">
    <figure>
      <img class="image-style" [src]="cocktail.imagePath" alt="{{cocktail.name}} cocktail">
      <figcaption class="image-name">{{cocktail.name}}</figcaption>
    </figure>
  </div>

</div>
