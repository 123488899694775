<input type="text"
       [attr.list]="dataListId"
       [ngClass]="textInputClass"
       [(ngModel)]="inputText"
       (ngModelChange)="inputChange.next($event)"
       [disabled]="disabled"
       [placeholder]="placeholderText"
       (blur)="resetInputText()"
>
<datalist [id]="dataListId" [ngClass]="datalistClass">
  <option *ngFor="let candidateEntry of valueCandidates | keyvalue" [value]="formatModel(candidateEntry.value)">
</datalist>

