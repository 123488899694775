<mat-dialog-content class="grid-container">
  <div>
    <h3 class="modal-title">Ingredient Suggestions</h3>
  </div>


  <div *ngIf="ingredients.length > 0" class="content-grid">

    <div class="table-column">
      <div class="table-container">
        <p class="entry-count">our suggestions</p>

        <div class="container-scrollable">
          <table class="table align-baseline equal-columns">
            <tbody>
            <tr class="list-row" *ngFor="let ingredient of ingredients; index as i">
              <td [ngClass]="{'ingredient-row-selected': i === currentIngredient, 'ingredient-row': true}" (click)="currentIngredient = i">
                {{ingredient.name}}
                <span>{{ingredient.possibleCocktails.length}} new</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <div class="cocktail-column">
      <div class="cocktail-container">
        <div class="cocktail-container-grid">
          <div *ngFor="let cocktail of currentIngredientCocktails; index as i" class="mb-3 cocktail-item">
            <div class="card h-100">
              <div class="container-fluid card-header">{{ cocktail.name }}</div>
              <div class="card-body">
                <img src="{{ cocktail.imagePath }}" class="img-fluid" alt="Responsive image">
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex add-action">
          Happy with the new cocktails? Add {{ingredients[currentIngredient].name}} to your List
          <button (click)="addIngredient()" class="btn rounded-button">
            Add
            <i class="bi bi-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>

  </div>
  <h4 *ngIf="ingredients.length === 0" class="text-center">Loading...</h4>

</mat-dialog-content>
